<template>
  <div class="webstore-index">
    <div class="container1200">
      <div class="white-bg">
        <section class="banner">
          <el-carousel trigger="click" height="380px" arrow="never">
            <el-carousel-item v-for="(v,i) in bannerList" :key="i">
              <img :src="v.banner" alt="" class="cursor-pointer" @click="bannerJump(v)">
            </el-carousel-item>
            <el-carousel-item v-if="!bannerList.length">
              <img src="../../assets/images/webstore/banner.png" alt="">
            </el-carousel-item>
          </el-carousel>
          <div class="user-info">
            <div class="user-avatar d-flex align-items-center">
              <img :src="userInfo.avatar" alt="" />
              <span>{{userInfo.nick_name}}</span>
            </div>
            <ul class="menu-list m-0 p-0">
              <li>
                <i class="ri-shopping-cart-2-line"></i>
                <!-- <router-link style="color:#fff" to="/webstorecart">Shopping cart</router-link> -->
                <router-link style="color:#fff" to="">Shopping cart</router-link>
              </li>
              <li>
                <i class="ri-file-list-2-line"></i>
                <!-- <router-link style="color:#fff" to="/MyOrder">My Order</router-link> -->
                <router-link style="color:#fff" to="">My Order</router-link>
              </li>
              <li>
                <i class="ri-exchange-line"></i>
                <router-link style="color:#fff" to="/myexchange">Exchange Record</router-link>
              </li>
              <li>
                <i class="ri-map-pin-5-line"></i>
                <router-link style="color:#fff" to="/storeaddress1">Shopping Address</router-link>
              </li>
            </ul>
          </div>
        </section>
        <section class="gift-shop px-3">
          <div class="gift-header">
            <div class="d-flex justify-content-between align-items-center">
              <img class="icon" src="../../assets/images/icon/gift-line.png" alt="" />
              <h3 class="ml-2">Gift shop</h3>
            </div>
            <router-link to="/pointsmerchandise" class="to_all"><span>All</span><i class="pl-2">></i></router-link>
          </div>
          <div class="row mt-2" v-if="giftShopList.length">
            <div class="shop-list cursor-pointer  col-6 col-lg-3" v-for="(v,i) of giftShopList" :key="i"
              @click="$router.push(`/shopscoredetails?id=${v.id}`)">
              <el-image :src="v.product_image" />
              <div class="shop-name">
                <h3 class="p-0 m-0">{{v.product_name}}</h3>
                <p class="p-0 m-0">{{v.points}} Points</p>
              </div>
            </div>
          </div>
          <div style="background:#fff;" class="d-flex justify-content-center align-items-center py-5" v-else>
            <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" />
          </div>
        </section>
      </div>
    </div>

    <!-- <div class="gray-bg menu-select">
      <div class="container1200">
        <ul class="row m-0 p-0">
          <li class="col-md-2 col-sm-3" v-for="(item, index) in 6" :key="index">
            <span>Men's T-shirts</span>
          </li>
        </ul>
      </div>
    </div> -->

    <!-- popular-recommend -->
    <!-- <div class="container1200">
      <div class="gray-bg pt-3">
        <div class="popular-recommend white-bg px-3">
          <div class="gift-header">
            <div class="d-flex justify-content-between align-items-center">
              <img class="icon" src="../../assets/images/icon/gift-line.png" alt="" />
              <h3 class="ml-2">Commodity</h3>
            </div>
            <router-link to="" class="to_all"><span>All</span><i class="pl-2">></i></router-link>
          </div>
          <div class="row mt-2" v-if="commodity.length">
            <div v-for="(v,i) of commodity" :key="i" class="shop-list col-6 col-md-3">
              <img src="../../assets/images/webstore/goods3.png" />
              <div class="shop-name">
                <h3 class="p-0 m-0">TRANDMON</h3>
                <p class="p-0 m-0">99 Points</p>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center py-5" v-else>
            <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" />
          </div>
        </div>
      </div>
    </div> -->

    <!-- hot-spot -->
    <!-- <div class="container1200">
      <div class="gray-bg hot-spot px-3">
        <div class="gift-header">
          <div class="d-flex justify-content-between align-items-center">
            <img class="icon" src="../../assets/images/icon/gift-line.png" alt="" />
            <h3 class="ml-2">Store</h3>
          </div>
          <router-link to="" class="to_all"><span>All</span><i class="pl-2">></i></router-link>
        </div>
        <div class="row" v-if="false">
          <div class="col-md-4 col-sm-6" v-for="(item, index) in 8" :key="index">
            <iq-card>
              <div class="card-header d-flex justify-content-between align-items-center">
                <div>
                  <img src="../../assets/images/user/09.jpg" alt="" />
                  <span>dsavvf</span>
                </div>
                <span class="btn btn-primary d-block"> View </span>
              </div>
              <ul class="img-list m-0 p-3">
                <li v-for="(item, index) in 3" :key="index">
                  <img src="../../assets/images/webstore/goods2.png" alt="" />
                </li>
              </ul>
            </iq-card>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center py-5" v-else>
          <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
  export default {
    data() {
      return {
        userInfo: {},
        giftShopList: [],
        bannerList: [],
        commodity: [],
      }
    },

    components: {},

    created() {
      this._mall()
    },
    mounted() {

    },
    methods: {
      async _mall() {
        let res = await this.$http.mall()
        if (res.status == 200) {
          this.userInfo = res.data.user
          this.giftShopList = res.data.gift_shop
          this.bannerList = res.data.banners
          this.commodity = res.data.commodity
        }
      },

      // 点击banner图跳转
      bannerJump(v) {
        if (v.type == 1) {
          if (v.product_exist == 0) return this.$message.warning('Product has been removed !')
          this.$router.push(`/shopscoredetails?id=${v.product_id}`)
        } else if (v.type == 2) {
          // 商家
        } else if (v.type == 3) {
          window.open(v.link)
        }
      }

    }
  }
</script>
<style lang="scss" scoped>
  .webstore-index {
    padding-top: 75px;

    .white-bg {
      background-color: #fff;
    }

    .gray-bg {
      background-color: #fafafb;
    }

    .banner {
      position: relative;
      height: 380px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .user-info {
        position: absolute;
        top: 0;
        left: 0;
        width: 285px;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 16px;
        z-index: 98;

        >.user-avatar {
          padding-bottom: 24px;

          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 16px;
          }

          span {
            color: #fff;
          }
        }

        >.menu-list {
          >li {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            color: #fff;
            padding: 16px 0;

            i {
              font-size: 18px;
              padding-right: 22px;
            }

            &:last-child {
              border: none;
            }
          }
        }
      }
    }

    .gift-shop,
    .popular-recommend,
    .hot-spot {
      padding-top: 50px;

      .gift-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 30px;
      }

      .to_all {
        display: flex;
        align-items: center;

        span {
          font-size: 18px;
          text-decoration: underline;
        }

        i {
          font-style: normal;
          font-size: 26px;
          font-weight: 300;
        }
      }

      .shop-list {
        .shop-name {
          text-align: center;
          padding: 30px 10px 50px;

          >h3 {
            color: #666;
            font-size: 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          p {
            font-size: 20px;
            color: #000;
            font-weight: 500;
          }
        }

        ::v-deep img {
          display: block;
          margin: 0 auto;
          width: 280px;
          height: 280px;
          object-fit: cover;
        }
      }

      .view-all-btn {
        width: 200px;
        height: 50px;
        margin: 0 auto;
        border: 1px solid #333;
        text-align: center;
        line-height: 50px;
      }
    }

    .menu-select {
      ul {
        display: flex;

        li {
          flex: 1;
          // width: 183px;
          height: 45px;
          margin-right: 20px;
          border: 1px solid #999;
          text-align: center;
          line-height: 45px;
          margin-top: 50px;
          margin-bottom: 50px;
        }
      }
    }

    .card-header {
      >div {
        img {
          width: 50px;
          height: 50px;
          margin-right: 20px;
        }
      }
    }

    ul.img-list {
      display: flex;

      >li {
        width: 37.3333%;
        margin-right: 10px;
        flex: 1;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
</style>